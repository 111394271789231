import axios from 'axios';
import localConfig from './config/local.json';

export const initConfig = async () => {
  if (process.env.NODE_ENV !== 'production') {
    window.config = localConfig;
  } else {
    const { data } = await axios.get('/config.json');
    window.config = data;
  }
};

const getConfig = (key) => window.config[key];

export default getConfig;
