import styled from 'styled-components';

const StyledValue = styled.p`
  font-weight: bold;
`;

const StyledAlert = styled.div`
  margin-right: 60px;
  &:last-child {
    margin-right: 0;
  }
`;

const Alert = ({ identifier, quantity }) => {
  const labelMapping = {
    lowStock: 'Low stock',
    latePurchases: 'Late purchases',
    openSales: 'Open sales'
  };
  return (
    <StyledAlert>
      <p>{labelMapping[identifier]}</p>
      <StyledValue>{quantity}</StyledValue>
    </StyledAlert>
  );
};

export default Alert;
