import axios from 'axios';
import getConfig from '../configHelper';

const getAlerts = async () => {
  const baseURL = getConfig('BASE_URL');
  const { data } = await axios.get('/alerts', { baseURL });
  return Object.entries(data).map(([key, value]) => ({ label: key, value }));
};

export const getStockValue = async () => {
  const baseURL = getConfig('BASE_URL');
  const { data } = await axios.get('/stock-value', { baseURL });
  return data;
};

export default getAlerts;
