import Alert from './Alert';
import styled from 'styled-components';

const AlertsContainer = styled.div`
  display: flex;
`;

const Alerts = ({ alerts }) => {
  return (
    <AlertsContainer>
      {
        alerts.map((alert, index)=> (
          <Alert identifier={alert.label} quantity={alert.value} key={index}/>
        ))
      }
    </AlertsContainer>
  );
};

export default Alerts;
