import getAlerts, { getStockValue } from './services/foo';
import React, { useEffect, useState } from 'react';
import { Card, PageHead } from '@myob/myob-widgets';
import Alerts from './components/Alerts';
import StockValueChart from './components/StockValueChart';

const CardHeader = () => <PageHead title="Inventory" />;

const App = () => {
  const [ alerts, setAlerts] = useState([]);
  const [ stockValue, setStockValue] = useState();

  useEffect( () => {
    (async () => {
      setAlerts(await getAlerts());
      setStockValue(await getStockValue());
    })();
  },[]);

  return (
    <Card header={<Card.Header child={<CardHeader />} />}>
      <Alerts alerts={alerts}/>
      {stockValue && <StockValueChart data={stockValue} />}
    </Card>
  );
};
export default App;
